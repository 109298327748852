@import "../../mixins.scss";

.croix-rouge {

  .lateralBarView {
    height: 100vh;
    background: #fff !important;
    color: white;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    position: fixed;
    //width: 120px;
    width: 87px;
    border-right: solid 1px rgba(0, 0, 0, 0.15);

    &.menuShow {
      border-right: solid 2px rgba(0, 0, 0, 0.15);
    }

    .numero-lateral {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 87px);
    }

    button.menu-toggle-btn {
      width: calc(100% + 2px);
      height: 87px;
      border: 0 !important;
      margin: 0;
      background: $red;
      // border-bottom: 1px solid #245258 !important;
      transition: all 0.2s ease-out;
      transform: translateX(0px);
      cursor: pointer;

      svg.menu-icon-on {
        transform: scale(0.75) translate(-3px, -4px);
      }

      g {
        rect {
          transition: all 0.25s ease-out;
        }
      }

      &.menuShow {
        background: $red;
        transition: all 0.4s ease-out;

        .menu-icon-on {
          g {
            rect{
              &:nth-child(1){
                transform: scaleX(0);
                transition: all 0.25s ease-in;
              }
              &:nth-child(2){
                transform: rotate(-42deg) translate(-18%, 13%);
                transition: all 0.17s ease-in-out 0.10s;
              }
              &:nth-child(3){
                transform: rotate(42deg) translate(4%, -22%);
                transition: all 0.17s ease-in-out 0.10s;
              }
              &:nth-child(4){
                transform: scaleX(0);
                transition: all 0.25s ease-in;
              }
            }
          }
        }


      }

      @at-root body:not(.touch) #{&} {
        &:hover {
          background-color: darken($red, 5%);
          transition: all 0.1s ease-out;
          border-bottom: 0;
        }
      }

    }

    .numero-info {
      position: relative;
      flex-basis: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
      height: auto;
      padding: 1em;
      margin-top: 0;

      .numeroInfo {
        transform: rotateZ(180deg);
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: black;
        font-size: 0.93rem;

        a.ridmi-link {
          transform: rotate(180deg);
          margin-top: 2rem;
          .logo {
            background-image: url(../components/assets/byridmi.svg);
            width: 112px;
            height: 60px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        span.date {
          writing-mode: vertical-rl;
          position: relative;

          &:after {
            @include wait(#fff);
          }

        }

        span.number {
          writing-mode: vertical-rl;
          margin-top: 3rem;
          font-weight: 400;
          letter-spacing: 5px;
          position: relative;

          &:after {
            @include wait(#fff);
          }

        }

      }

    }

    .numero-socials {

    }

    .socials {

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0.7rem;

        &:after {
          @include wait(#fff);
        }

        li {
          margin: 0.5rem;
          position: relative;

        }

      }

      a {
        color:  black;

        span {
          text-indent: -9999px;
          display: block;
          overflow: hidden;
          width: 0;
          height: 0;
        }

      }

    }

  }

}

@import "./media-lateral-bar.scss";

@font-face {
  font-family: 'icomoon-cr';
  src:  url('icomoon.eot');
  src:  url('icomoon.eot') format('embedded-opentype'),
  url('icomoon.ttf?9f8084') format('truetype'),
  url('icomoon.woff?9f8084') format('woff'),
  url('icomoon.svg?9f8084#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-cr-"], [class*=" ico-cr-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-cr' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-cr-close:before {
  content: "\e90c";
}
.ico-cr-burger:before {
  content: "\e900";
}
.ico-cr-chevron-down:before {
  content: "\e901";
}
.ico-cr-chevron-left:before {
  content: "\e902";
}
.ico-cr-chevron-right:before {
  content: "\e903";
}
.ico-cr-chevron-up:before {
  content: "\e904";
}
.ico-cr-next-article:before {
  content: "\e905";
}
.ico-cr-no-read:before {
  content: "\e906";
}
.ico-cr-prev-article:before {
  content: "\e907";
}
.ico-cr-read:before {
  content: "\e908";
}
.ico-cr-search:before {
  content: "\e909";
}
.ico-cr-start-reading:before {
  content: "\e90a";
}
.ico-cr-time:before {
  content: "\e90b";
}

@import "../../mixins.scss";

.croix-rouge {
  .cible-view {
    //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99);
    will-change: transform;
    position: relative;
    overflow: hidden;

    .status {
      cursor: pointer;
    }

    .infos {
      display: flex;
      margin-bottom: 0.8rem;

      @media (max-width: 425px){
        margin-bottom: 0.95rem;
        padding-left: 12px;
        padding-right: 12px;
      }

      .outer-i {
        margin-left: 2rem;

        @media (max-width: 425px) {
          padding-top: 2px;
        }

      }
    }

    img, .fuck {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      will-change: opacity;
      opacity: 0;

      &.loaded {
        opacity: 1;
        transition: opacity 0.6s ease 0.200s;
      }

    }

    h2 {
      color: white;
    }

    .chapitre {
      color: white;

      .number {
        color: #fff;
        background: #f95e7d;
        padding: 0.2rem;
        padding-top: 7px;
        font-weight: 700;
        font-size: 14px;
        margin-right: 15px;
        width: 27px;
        height: 27px;
        border-radius:100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: 425px) {
          padding-top: 3px;
          margin-right: 10px;
        }
      }

    }

    .inner-head {
      height: 100%;
      width: 100%;
      position: relative;

      &.not-ready{
        &:after {
          @include wait(#ececec)
        }
      }

      &:after {
        @include wait(#ececec)
      }

      @keyframes animTest {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      img {
        opacity: 0;

        &.active {
          opacity: 1;
        }
        &.loaded {
          opacity: 1;
          transition: opacity 0.6s ease 0.200s;
        }


      }

      &.ready {
        //
        //.fx-img {
        //  opacity: 1;
        //}
        //
        //img {
        //  opacity: 1;
        //}

      }

    }

    .fx-img {
      //display: none !important;
      top: -74%;
      left: -100%;
      width: 200%;
      height: 200%;
      content: "";
      position: absolute;
      //opacity: 1;
      // background: rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.45);
      //background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 27%);
      z-index: 2;
      //transition: all 0.2s ease-out;
    }

    .imageView {
      width: 101%;
      height: 101%;
      will-change: transform;
      object-fit: cover;
      top: 0;
      left: 0;
      position: absolute;

      img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        will-change: opacity;
        opacity: 0;
      }
      //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
    }

    .start-btn {
      will-change: transform;
      //transition: all 0.50s ease-in-out 0.20s !important;
    }

    &.article-view {
      // height: 78vh !important;
      //transform: matrix(0.99999, 0, 0, 0.99999, 0, -200);
      will-change: height;
      // transition: height 0.4s ease;
      height: 600px;

      .inner {
        width: 100%;

        @media (max-width: 375px) {
          margin-bottom: 3%;
          bottom: 0%;
        }

      }

      @media (max-width: 1024px) {
        &:not(.no-img) {
          height: 50vh !important;
        }
      }

      @media (max-width: 375px) {
        height: 50vh !important;
        &:not(.no-img) {
          height: 65vh !important;
        }
      }

      &.breves {
        //height: 60vh !important;
        //transform: matrix(0.99999, 0, 0, 0.99999, 0, -200);

      }

      &.no-img {
        h1, h2 {
          color:black;
        }
        .chapitre {
          color: black;
        }
        .time, .status {
          color: black;
        }
        .time i, .status i {
          color: black;
        }
      }

      .start-btn {
        opacity: 0;
      }

      h2 {
        width: 100% !important;
      }

      img, .fx-img{
        //transform: translateY(200px);
        //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
      }

      .fx-img {
        //transform: translateY(200px);
      }

      .start-btn {
        //transform: translateY(101%);
        //transform-origin: bottom;
      }
    }
    &.couverture-view {
      height: 78vh !important;

      @media (max-width: 375px) {
        height: 72vh !important;
      }

      .time, .status {
        /*&:after {
          @include wait(#ececec)
        }*/
      }

      .start-btn {
        //transform: translateY(0%);
        //transform-origin: bottom;
        @media (max-width: 768px) {
          width: auto;
        }
      }

    }
  }
}

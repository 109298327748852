//     padding-right: 1rem;

@media (min-height: 900px) and (max-width: 1025px){

  .croix-rouge {
    .main {
      .header {
        padding-right: 1rem;
        width: calc(100% - 72px);



        .scroll-block {
          & > div:first-child {
            flex-basis: 0;
            display: none;
          }
          .scroll-inner {
            flex-basis: calc(100%);
            max-width: 100%;
          }
        }

      }
    }
  }

}

@media (max-width: 425px) {

  .croix-rouge {
    .main {
      .header {
        width: calc(100%);
        height: 60px;

        &.menuShow {
          .inner h1 a {
            color: white;
          }
        }

        &.menuShow {
          // background: #2a575d;
          background: white;

          .scroll-block {
            visibility: hidden;
          }

        }

        .scroll-block {
          position: absolute;
          top: 0;
          transform: translateY(-1px);
        }

        .menu-numero {
          display: none;
        }

      }
    }
  }

}

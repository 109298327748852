@import "../../mixins.scss";

.croix-rouge {

  .contact {
    .subtitle-page {
      h2 {
        font-size: 2rem;
        line-height: 2rem;
        margin-top: 3rem;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
        font-weight: 400;
        color: black;
      }
    }
  }

  .form-outer {

    span.err {
      margin-top: 0.6rem;
      display: flex;
      font-size: 0.85rem;
      padding-left: 0.7rem;
      color: $red;
    }

    .form-message {
      margin-top: 1.5rem;
      padding: 1rem;
      font-size: 0.9rem;
      color: red;
      border: solid 1px red;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      &.ok {
        border: solid 1px green;
        color: green;
      }
    }

    input[type="submit"] {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem;
      margin-top: 2rem;
      background-color: $red;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      outline:none;
      &:hover , &:active{
        background: darken($red, 5%);
        outline:none;
      }
    }

    .inputBloc {
      border: solid 1px lighten(black, 50%);
      border-radius: 5px;
      position: relative;
      padding: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-top: 1.6rem;
      z-index: 0;

      &.isFocus {
        label {
          top: -13px;
          left: 16px;
          font-size: 0.7rem;
          display: block;
          padding: 0.4rem;
          background: white;
        }
      }

      label {
        position: absolute;
        top: 21px;
        left: 26px;
        transition: all 0.15s ease;
        z-index: -1;
      }

      input, textarea {
        width: 100%;
        border: 0;
        outline:none;
        font-size: 1rem;
        font-weight: bold;
        background: transparent;
      }

      &:not(.isFocus) {
        &:not(.isEmpty){
          label {
            top: -13px;
            left: 16px;
            font-size: 0.7rem;
            display: block;
            padding: 0.4rem;
            background: white;
          }
        }
      }

      &.error {
        border: solid 2px $red;
        label {
          color: $red;
        }
      }
    }
  }

  .page-outer {
    padding-top: calc(87px + 3rem);
    padding-bottom: 4rem;

    @media (max-width: 425px) {
      padding-top: calc(87px + 1rem);
    }


    .page-inner {
      max-width: 700px;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;

      &.contact {

        .contact-form-page-outer {

          .infos-principales {
            background: lighten(black, 95%);
            padding: 2rem;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              width: 50vw;
              transform: translateX(100%);
              right: 0;
              height: 100%;
              background: lighten(black, 95%);
            }
          }

          .content {
            margin-top: 2.5rem;
          }

        }

      }

      .title-page {
        position: relative;
        display: flex;
        align-self: flex-start;
        margin-bottom: 1rem;

        &:after {
          @include wait(#e7e6e9)
        }
      }

      h1 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 3rem;
      }

    }

  }


}
